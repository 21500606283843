import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ParamProject } from '../models/paramProject';

@Injectable({
  providedIn: 'root',
})
export class ParamProjectService {
  endpoint = `${environment.api}/paramProject`;
  _paramProject: BehaviorSubject<ParamProject> = new BehaviorSubject<ParamProject>(null);

  constructor(private http: HttpClient) {}
  set paramProject(value: ParamProject) {
    // Store the value
    this._paramProject.next(value);
  }

  get paramProject$(): Observable<ParamProject> {
    return this._paramProject.asObservable();
  }
  getParamProject(): Observable<ParamProject> {
    return this.http.get<ParamProject>(`${this.endpoint}`);
  }
  getConfirmationCodeAttempt(): Observable<null> {
    return this.http.get<null>(`${this.endpoint}/confirmationCodeAttempt`);
  }
  // update paramProject
  updateParam(paramProject: ParamProject): Observable<null> {
    return this.http.patch<null>(`${this.endpoint}`, { paramProject });
  }
}
