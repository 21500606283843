import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
  MatSnackBarRef,
} from '@angular/material/snack-bar';
import { SnackBarComponent } from '../components/snack-bar/snack-bar.component';

interface ISnackBarMessage {
  message: string;
  type: 'error' | 'success' | 'warning' | 'info';
  horizontalPosition: MatSnackBarHorizontalPosition;
  verticalPosition: MatSnackBarVerticalPosition;
  durationInSeconds: number;
}

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  private snackBarRef: MatSnackBarRef<SnackBarComponent>;
  private isInstanceVisible: boolean = false;
  private msgQueue: ISnackBarMessage[] = [];

  constructor(private snackBar: MatSnackBar) {}

  openSnackBar(
    message: string,
    type: 'error' | 'success' | 'warning' | 'info' = 'error',
    horizontalPosition: MatSnackBarHorizontalPosition = 'center',
    verticalPosition: MatSnackBarVerticalPosition = window.innerWidth >= 600 ? 'top' : 'bottom',
    durationInSeconds: number = 3,
  ): void {
    this.msgQueue.push({
      message,
      type,
      horizontalPosition,
      verticalPosition,
      durationInSeconds,
    });
    if (!this.isInstanceVisible) {
      this.showNext();
    }
  }

  showNext() {
    if (this.msgQueue.length === 0) {
      return;
    }

    let message: ISnackBarMessage = this.msgQueue.shift();
    this.isInstanceVisible = true;
    this.snackBarRef = this.snackBar.openFromComponent(SnackBarComponent, {
      data: { message: message.message, type: message.type },
      horizontalPosition: message.horizontalPosition,
      verticalPosition: message.verticalPosition,
      duration: message.durationInSeconds * 1000,
      panelClass: ['mat-snack-bar-panel-custom', `mat-snack-bar-custom-alert-${message.type}`],
    });
    this.snackBarRef.afterDismissed().subscribe(() => {
      this.isInstanceVisible = false;
      this.showNext();
    });
  }
}
