import {
  Component,
  OnInit,
  Output,
  ViewEncapsulation,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { environment } from '../../../../environments/environment';
import { TranslocoHttpLoader } from '../../../transloco/transloco-root.module';
import { Local } from '../../../shared/models/local';
import { User } from '../../../shared/models/user';
import { Subscription } from 'rxjs';
import { DataStoreService } from '../../../shared/data-store/data-store.service';

@Component({
  selector: 'app-app-languages',
  templateUrl: './languages.component.html',
  encapsulation: ViewEncapsulation.None,
  exportAs: 'languages',
})
export class LanguagesComponent implements OnInit, OnDestroy {
  @Output() orientation = new EventEmitter<string>();

  urlFlag = `${environment.api}/assets/`;
  selectedLang: Local;
  listLocals: Local[];
  private subscriptions: Subscription[] = [];
  /**
   * Constructor
   */

  constructor(
    private _translocoService: TranslocoService,
    private _translocoHttpLoader: TranslocoHttpLoader,
    private dataStoreService: DataStoreService,
  ) {}

  /**
   * On init
   */
  ngOnInit(): void {
    this.subscriptions.push(
      this.dataStoreService.user$.subscribe((user: User) => {
        if (!user) return;
        this._translocoHttpLoader.getAvailableLocals().subscribe((langs) => {
          this.listLocals = langs.map((lang) => ({ ...lang.localId }));
          this._translocoService.setAvailableLangs(this.listLocals.map((value) => value.key));
          const userLang: Local = this.listLocals.find((l) => l._id === user.defaultLocal);
          const sessionStorageLang: string = sessionStorage.getItem('selected-lang');
          if (
            !sessionStorageLang &&
            userLang?.key &&
            this._translocoService.isLang(userLang?.key)
          ) {
            this._translocoService.setActiveLang(userLang?.key);
          } else if (sessionStorageLang && this._translocoService.isLang(sessionStorageLang)) {
            this._translocoService.setActiveLang(sessionStorageLang);
          } else {
            this._translocoHttpLoader.getDefaultLocal().subscribe((lang) => {
              this._translocoService.setActiveLang(lang.key);
            });
          }
        });
      }),
    );
    this._translocoService.langChanges$.subscribe((lang: string) => {
      if (this.listLocals?.length) {
        this.selectedLang = this.listLocals.find((l) => l.key === lang);
      }
    });
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Set the active lang
   *
   * @param lang
   */
  setActiveLang(lang): void {
    // Set the active lang
    this._translocoService.setActiveLang(lang.key);
    this.orientation.emit(lang.orientation);
    sessionStorage.setItem('selected-lang', lang.key);
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  ngOnDestroy(): void {
    this.subscriptions.map((s) => s?.unsubscribe());
  }
}
