import { RefList } from '../models/ref-list';

export enum FeatureCodes {
  setting = 'setting',
  countries = 'countries',
  stage = 'stage',
  typePrice = 'type-price',
  destination = 'destination',
  currencies = 'currencies',
  administration = 'administration',
  company = 'company',
  features = 'features',
  groups = 'groups',
  users = 'users',
  paramProject = 'param-project',
  account = 'account',
  profile = 'profile',
  thirdPartyType = 'third-party-type',
  tms = 'tms',
  thirdParty = 'third-party',
  customer = 'customer',
  supplier = 'supplier',
  transporter = 'transporter',
  transportOrder = 'transport-order',
  transportOrderSpot = 'transport-order-spot',
  transportOrderTransporter = 'transport-order-transporter',
  transportOrderSupplier = 'transport-order-supplier',
  transporterContract = 'transporter-contract',
  editing = 'editing',
  volumeSD = 'volume-s-d',
  amountSD = 'amount-s-d',
  volumeST = 'volume-s-t',
  amountST = 'amount-s-t',
  editionListOfTransportOrders = 'edition-list-of-transport-orders',
  extraCostsST = 'extra-costs-s-t',
  kpiST = 'kpi-s-t',
  editListOfLitigiousCases = 'edit-list-of-litigious-cases',
  userFeatures = 'user-features',
  projects = 'projects',
}

export const listFeatureActions: RefList<FeatureCodes>[] = [
  { key: FeatureCodes.setting, value: 'Setting' },
  { key: FeatureCodes.countries, value: 'Countries' },
  { key: FeatureCodes.stage, value: 'Stage' },
  { key: FeatureCodes.typePrice, value: 'Type Price' },
  { key: FeatureCodes.destination, value: 'Destination' },
  { key: FeatureCodes.currencies, value: 'Currencies' },
  { key: FeatureCodes.administration, value: 'Administration' },
  { key: FeatureCodes.company, value: 'Company' },
  { key: FeatureCodes.features, value: 'Features' },
  { key: FeatureCodes.groups, value: 'Groups' },
  { key: FeatureCodes.users, value: 'Users' },
  { key: FeatureCodes.paramProject, value: 'Params Project' },
  { key: FeatureCodes.account, value: 'Account' },
  { key: FeatureCodes.profile, value: 'Profile' },
  { key: FeatureCodes.thirdPartyType, value: 'Third party type' },
  { key: FeatureCodes.tms, value: 'TMS' },
  { key: FeatureCodes.thirdParty, value: 'Third party' },
  { key: FeatureCodes.customer, value: 'Customer' },
  { key: FeatureCodes.supplier, value: 'Supplier' },
  { key: FeatureCodes.transporter, value: 'Transporter' },
  { key: FeatureCodes.transportOrder, value: 'Transport order' },
  { key: FeatureCodes.transportOrderSpot, value: 'Transport order spot' },
  { key: FeatureCodes.transportOrderTransporter, value: 'Transport order transporter' },
  { key: FeatureCodes.transportOrderSupplier, value: 'Transport order supplier' },
  { key: FeatureCodes.transporterContract, value: 'Transporter contract' },
  { key: FeatureCodes.editing, value: 'Editing' },
  { key: FeatureCodes.volumeSD, value: 'Volume (s/d)' },
  { key: FeatureCodes.amountSD, value: 'amount (s/d)' },
  { key: FeatureCodes.volumeST, value: 'Volume (s/T)' },
  { key: FeatureCodes.amountST, value: 'amount (s/T)' },
  { key: FeatureCodes.editionListOfTransportOrders, value: 'Edition list of transport orders' },
  { key: FeatureCodes.extraCostsST, value: 'Extra costs (S/T)' },
  { key: FeatureCodes.kpiST, value: 'Kpi (S/T)' },
  { key: FeatureCodes.editListOfLitigiousCases, value: 'Edit list of litigious cases' },
  { key: FeatureCodes.userFeatures, value: 'user-features' },
  { key: FeatureCodes.projects, value: 'projects' },
];
