<div class="flex flex-col max-w-150 md:min-w-120 max-h-screen -m-6">
  <!-- Header -->
  <div
    class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary"
  >
    <div class="text-lg font-medium">{{ 'Check password' | transloco }}</div>
  </div>
  <form class="w-full" #myForm="ngForm" ngForm="ngForm">
    <!-- Loader -->
    <mat-progress-bar *ngIf="isLoading" [mode]="'indeterminate'"></mat-progress-bar>
    <!-- Main -->
    <div class="flex flex-1 flex-col flex-auto overflow-hidden sm:overflow-y-auto">
      <div class="flex flex-col mb-6 w-full">
        <div class="grid sm:grid-cols-4 gap-6 p-3">
          <!-- password -->
          <div class="sm:col-span-4">
            <mat-form-field class="fuse-mat-no-subscript fuse-mat-emphasized-affix w-full">
              <mat-label>{{ 'Password' | transloco }}</mat-label>
              <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:key'" matPrefix></mat-icon>
              <input
                [(ngModel)]="password"
                pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{6,20}$"
                maxlength="20"
                required
                matInput
                name="password"
                type="password"
                #passwordField
                [disabled]="isLoading"
              />
              <button
                mat-icon-button
                type="button"
                (click)="
                  passwordField.type === 'password'
                    ? (passwordField.type = 'text')
                    : (passwordField.type = 'password')
                "
                matSuffix
              >
                <mat-icon
                  class="icon-size-5"
                  *ngIf="passwordField.type === 'password'"
                  [svgIcon]="'heroicons_solid:eye'"
                ></mat-icon>
                <mat-icon
                  class="icon-size-5"
                  *ngIf="passwordField.type === 'text'"
                  [svgIcon]="'heroicons_solid:eye-off'"
                ></mat-icon>
              </button>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="flex items-center justify-end mt-3 mr-3 mb-4">
      <!-- Discard -->
      <button class="ml-auto sm:ml-0 ml-4" type="button" mat-stroked-button (click)="discard()">
        {{ 'cancel' | transloco }}
      </button>
      <!-- Send -->
      <button
        class="order-first sm:order-last ml-4"
        mat-flat-button
        type="submit"
        [color]="'primary'"
        (click)="confirm()"
      >
        {{ 'confirm' | transloco }}
      </button>
    </div>
  </form>
</div>
