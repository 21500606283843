import { Component, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { TranslocoService } from '@ngneat/transloco';
import { TranslocoHttpLoader } from './transloco/transloco-root.module';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  availableLangs: string[];
  /**
   * Constructor
   */
  constructor(
    private dateAdapter: DateAdapter<any>,
    private translocoService: TranslocoService,
    private translocoHttpLoader: TranslocoHttpLoader,
  ) {}

  ngOnInit(): void {
    this.translocoHttpLoader.getAvailableLocals().subscribe((langs) => {
      this.availableLangs = langs.map((lang) => ({ ...lang.localId })).map((lang) => lang.key);
      this.translocoService.setAvailableLangs(this.availableLangs);
    });
    this.translocoHttpLoader.getDefaultLocal().subscribe((lang) => {
      this.translocoService.setActiveLang(lang.key);
    });
    this.translocoService.langChanges$.subscribe((lang: string) => {
      this.dateAdapter.setLocale(lang);
    });
  }
}
