<div class="fuse-vertical-navigation-wrapper">
  <!-- Header -->
  <div class="fuse-vertical-navigation-header">
    <ng-content select="[fuseVerticalNavigationHeader]"></ng-content>
  </div>

  <!-- Content -->
  <div
    class="fuse-vertical-navigation-content"
    appFuseScrollbar
    [fuseScrollbarOptions]="{ wheelPropagation: inner, suppressScrollX: true }"
    #navigationContent
  >
    <!-- Content header -->
    <div class="fuse-vertical-navigation-content-header">
      <ng-content select="[fuseVerticalNavigationContentHeader]"></ng-content>
    </div>

    <!-- Items -->
    <ng-container *ngFor="let item of navigation; trackBy: trackByFn">
      <!-- Skip the hidden items -->
      <ng-container *ngIf="(item.hidden && !item.hidden(item)) || !item.hidden">
        <!-- Aside -->
        <ng-container *ngIf="item.type === 'aside'">
          <app-fuse-vertical-navigation-aside-item
            [item]="item"
            [name]="name"
            [activeItemId]="activeAsideItemId"
            [autoCollapse]="autoCollapse"
            [skipChildren]="true"
            (click)="toggleAside(item)"
          ></app-fuse-vertical-navigation-aside-item>
        </ng-container>

        <!-- Basic -->
        <ng-container *ngIf="item.type === 'basic' && item.link">
          <app-fuse-vertical-navigation-basic-item
            [item]="item"
            [name]="name"
          ></app-fuse-vertical-navigation-basic-item>
        </ng-container>

        <!-- Collapsable -->
        <ng-container *ngIf="item.type === 'collapsable'">
          <app-fuse-vertical-navigation-collapsable-item
            [item]="item"
            [name]="name"
            [autoCollapse]="autoCollapse"
          ></app-fuse-vertical-navigation-collapsable-item>
        </ng-container>

        <!-- Divider -->
        <ng-container *ngIf="item.type === 'divider'">
          <app-fuse-vertical-navigation-divider-item
            [item]="item"
            [name]="name"
          ></app-fuse-vertical-navigation-divider-item>
        </ng-container>

        <!-- Group -->
        <ng-container *ngIf="item.type === 'group'">
          <app-fuse-vertical-navigation-group-item
            [item]="item"
            [name]="name"
            [autoCollapse]="autoCollapse"
          ></app-fuse-vertical-navigation-group-item>
        </ng-container>

        <!-- Spacer -->
        <ng-container *ngIf="item.type === 'spacer'">
          <app-fuse-vertical-navigation-spacer-item
            [item]="item"
            [name]="name"
          ></app-fuse-vertical-navigation-spacer-item>
        </ng-container>
      </ng-container>
    </ng-container>
    <div class="flex justify-center align-middle py-1">
      <button
        (click)="logout()"
        class="text-red-500 flex flex-row w-11/12 h-10 rounded-md hover:bg-gray-100 pt-0.5 hover:bg-opacity-10 align-middle"
      >
        <mat-icon
          class="icon-size-7 ml-4 mr-3"
          [svgIcon]="'heroicons_outline:logout'"
          color="warn"
        ></mat-icon>
        <span class="mt-1">{{ 'Sign out' | transloco }}</span>
      </button>
    </div>

    <!-- Content footer -->
    <div class="fuse-vertical-navigation-content-footer">
      <ng-content select="[fuseVerticalNavigationContentFooter]"> </ng-content>
    </div>
  </div>

  <!-- Footer -->
  <div class="fuse-vertical-navigation-footer">
    <ng-content select="[fuseVerticalNavigationFooter]"></ng-content>
  </div>
</div>

<!-- Aside -->
<ng-container *ngIf="activeAsideItemId">
  <div
    class="fuse-vertical-navigation-aside-wrapper"
    appFuseScrollbar
    [fuseScrollbarOptions]="{ wheelPropagation: false, suppressScrollX: true }"
    [@fadeInLeft]="position === 'left'"
    [@fadeInRight]="position === 'right'"
    [@fadeOutLeft]="position === 'left'"
    [@fadeOutRight]="position === 'right'"
  >
    <!-- Items -->
    <ng-container *ngFor="let item of navigation; trackBy: trackByFn">
      <!-- Skip the hidden items -->
      <ng-container *ngIf="(item.hidden && !item.hidden(item)) || !item.hidden">
        <!-- Aside -->
        <ng-container *ngIf="item.type === 'aside' && item.id === activeAsideItemId">
          <app-fuse-vertical-navigation-aside-item
            [item]="item"
            [name]="name"
            [autoCollapse]="autoCollapse"
          ></app-fuse-vertical-navigation-aside-item>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
