import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { Pagination } from '../models/pagination';
import { Company } from '../models/company';
import { City } from '../models/city';
import { Country } from '../models/country';
import { Site } from '../models/site';
import { Currency } from '../models/currency';
import { UserSite } from '../models/user-site';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  endpointAuth = `${environment.services.auth.url}/companies`;

  _company = new BehaviorSubject<Company>(null);
  _site = new BehaviorSubject<Site>(null);
  _sites: BehaviorSubject<Site[]> = new BehaviorSubject<Site[]>(null);

  constructor(private http: HttpClient) {}
  /**
   * Setter & getter for features
   *
   * @param value
   */
  set sites(value: Site[]) {
    // Store the value
    this._sites.next(value);
  }

  // add new company
  createCompany(company: Company, sites: Site[]): Observable<Company> {
    return this.http.post<Company>(`${this.endpointAuth}`, { company, sites });
  }
  // update logo company
  updateLogo(data: FormData, id): Observable<Company> {
    return this.http.post<Company>(`${this.endpointAuth}/${id}/logo`, data);
  }
  // get companies
  getCompanies(
    limit,
    page,
    sortCode,
    sortName,
    sortEmail,
    search,
  ): Observable<Pagination<Company>> {
    let searchParams = new HttpParams();
    searchParams = searchParams.append('limit', limit);
    searchParams = searchParams.append('page', page);
    if (search) {
      searchParams = searchParams.append('search', search);
    }
    if (sortCode) {
      searchParams = searchParams.append('sortCode', sortCode);
    }
    if (sortName) {
      searchParams = searchParams.append('sortName', sortName);
    }
    if (sortEmail) {
      searchParams = searchParams.append('sortDL', sortEmail);
    }
    return this.http.get<Pagination<Company>>(`${this.endpointAuth}`, {
      params: searchParams,
    });
  }
  getCompany(): Observable<Company> {
    return this.http.get<Company>(`${this.endpointAuth}/me`);
  }
  getSites(id): Observable<Site[]> {
    return this.http.get<Site[]>(`${this.endpointAuth}/${id}/sites`);
  }
  getSitesSuper(id): Observable<UserSite[]> {
    return this.http.get<UserSite[]>(`${this.endpointAuth}/${id}/sites-super`);
  }
  getSite(id): Observable<Site> {
    return this.http.get<Site>(`${this.endpointAuth}/${id}/site`);
  }
  getUserSite(id): Observable<UserSite> {
    return this.http.get<UserSite>(`${this.endpointAuth}/${id}/user-site`);
  }
  getUserSites(): Observable<UserSite[]> {
    return this.http.get<UserSite[]>(`${this.endpointAuth}/user-sites`);
  }
  getCities(id): Observable<City[]> {
    return this.http.get<City[]>(`${this.endpointAuth}/city/${id}`);
  }

  getCountries(): Observable<Country[]> {
    return this.http.get<Country[]>(`${this.endpointAuth}/country`);
  }
  getCurrencies(): Observable<Currency[]> {
    return this.http.get<Currency[]>(`${this.endpointAuth}/currency`);
  }

  deleteCompany(id): Observable<null> {
    return this.http.delete<null>(`${this.endpointAuth}/${id}`);
  }

  updateCompany(company: Company, sites: Site[], deletedSites: string[]): Observable<null> {
    return this.http.patch<null>(`${this.endpointAuth}`, { company, sites, deletedSites });
  }

  getListCompanies(): Observable<Company[]> {
    return this.http.get<Company[]>(`${this.endpointAuth}/all`);
  }

  getSitesMenu(): Observable<Site[]> {
    return this.http.get<Site[]>(`${this.endpointAuth}/sites-menu`);
  }
}
