import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { BooleanInput } from '@angular/cdk/coercion';
import { Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Company } from '../../../shared/models/company';
import { User } from '../../../shared/models/user';
import { CompanyService } from '../../../shared/services/company.service';
import { UserService } from '../../../shared/services/user.service';
import { Site } from '../../../shared/models/site';
import { MatDialogService } from '../../../shared/services/mat-dialog.service';
import { MatDialogRef } from '@angular/material/dialog';
import { ParamProjectService } from '../../../shared/services/param-project.service';
import { ParamProject } from '../../../shared/models/paramProject';
import { AuthService } from '../../../core/auth/auth.service';
import { PasswordConfirmationComponent } from './password-confirmation/password-confirmation.component';
import { Types } from '../../../shared/enums/types';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  encapsulation: ViewEncapsulation.None,
  exportAs: 'user',
})
export class UserComponent implements OnInit, OnDestroy {
  /* eslint-disable @typescript-eslint/naming-convention */
  static ngAcceptInputType_showAvatar: BooleanInput;
  /* eslint-enable @typescript-eslint/naming-convention */
  @Output() refreshSite: EventEmitter<null> = new EventEmitter<null>();
  @Output() refreshCompany: EventEmitter<null> = new EventEmitter<null>();
  @Output() loadingSites = new EventEmitter();
  @Input() showAvatar = true;

  user: User;
  urlAvatar = `${environment.services.auth.url}/public/avatar/`;
  listCompanies: Company[];
  listSites: Site[];
  connectedCompany: Company;
  connectedSite: Site;
  changePassword: MatDialogRef<any>;
  paramProject: ParamProject;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  url = environment.api;
  urlGuide = `${this.url}/docs/tms-user-guide.pdf`;
  releaseNotes = `${this.url}/docs/release-notes.pdf`;
  /**
   * Constructor
   */
  constructor(
    private _router: Router,
    private userService: UserService,
    private companyService: CompanyService,
    private matDialogService: MatDialogService,
    private paramProjectService: ParamProjectService,
    private _authService: AuthService,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to user changes
    this.userService.user$.subscribe((user: User) => {
      if (user.type === Types.super) {
        this.companyService.getListCompanies().subscribe(
          (companies) => {
            this.listCompanies = companies;
            this.connectedCompany = user?.companyId;
            this.getSites(user);
          },
          () => {},
        );
      } else {
        this.getSites(user);
      }
    });
  }

  getSites(user) {
    this.companyService.getSitesMenu().subscribe(
      (sites) => {
        this.listSites = sites;
        this.companyService._sites.next(sites);
        const connectedSite = localStorage.getItem('connectedSite');
        if (connectedSite) {
          this.connectedSite = this.listSites.find((l) => l._id === connectedSite);
        } else {
          this.connectedSite = user?.defaultSite;
        }
        this.user = user;
      },
      () => {},
    );
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Sign out
   */
  signOut(): void {
    this._router.navigate(['/sign-out']);
  }

  redirectTo(link: string) {
    this._router.navigate([link]);
  }

  refreshTokenCompany(company, menuTrigger, password = null) {
    this._authService.refreshToken({ companyId: company._id, password }).subscribe(() => {
      this.connectedCompany = company;
      this.companyService._company.next(company);
      this.refreshCompany.next(null);
      this.companyService.getSitesMenu().subscribe(
        (sites) => {
          this.listSites = sites;
          if (this.connectedCompany._id !== this.user.companyId._id) {
            this.connectedSite = this.listSites[0];
          } else {
            this.connectedSite = this.user.defaultSite;
          }
          this.refreshTokenSite(this.connectedSite, menuTrigger, password);
        },
        () => {},
      );
    });
  }

  switchCompany(company, menuTrigger, event) {
    event?.stopPropagation();
    this.paramProjectService.getParamProject().subscribe(
      (paramProject) => {
        this.paramProject = paramProject;
        if (!paramProject?.suspendPassword) {
          this.changePassword = this.matDialogService.openDialog(PasswordConfirmationComponent);
          this.changePassword.afterClosed().subscribe((password: string) => {
            if (password) {
              this.refreshTokenCompany(company, menuTrigger, password);
            }
          });
        } else {
          this.refreshTokenCompany(company, menuTrigger);
        }
      },
      () => {},
    );
  }

  refreshTokenSite(site, menuTrigger, password = null) {
    this._authService.refreshToken({ connectedSite: site?._id, password }).subscribe(() => {
      this.connectedSite = site;
      this.companyService._site.next(site);
      localStorage.setItem('connectedSite', this.connectedSite?._id);
      this.refreshSite.next(null);
      menuTrigger.closeMenu();
    });
    this._router.navigate(['/home/profile']);
  }

  switchSite(site, menuTrigger, event) {
    event?.stopPropagation();
    this.paramProjectService.getParamProject().subscribe(
      (paramProject) => {
        this.paramProject = paramProject;
        if (!paramProject?.suspendPassword) {
          this.changePassword = this.matDialogService.openDialog(PasswordConfirmationComponent);
          this.changePassword.afterClosed().subscribe((password: string) => {
            if (password) {
              this.refreshTokenSite(site, menuTrigger, password);
            }
          });
        } else {
          this.refreshTokenSite(site, menuTrigger);
        }
      },
      () => {},
    );
  }
}
