import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, of, Subscriber } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';
import { switchMap } from 'rxjs/operators';
import { UserService } from '../../../shared/services/user.service';
import { MenuService } from '../../../shared/services/menu.service';
import { FeatureAuth } from '../../../shared/models/feature-auth';
import { DataStoreService } from '../../../shared/data-store/data-store.service';
import { FuseNavigationItem } from '../../../../@fuse/components/navigation';

@Injectable({
  providedIn: 'root',
})
export class IsAuthorizedGuard implements CanActivate {
  features: FeatureAuth[];

  /**
   * Constructor
   */
  constructor(
    private _authService: AuthService,
    private _router: Router,
    private userService: UserService,
    private menuService: MenuService,
    private dataStoreService: DataStoreService,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Can activate
   *
   * @param route
   * @param state
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
    const routeFeature = route.data['feature'];
    return this._check(redirectUrl, routeFeature);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
    const routeFeature = childRoute.data['feature'];
    return this._check(redirectUrl, routeFeature);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Check the authenticated status
   *
   * @param redirectURL
   * @param routeFeature
   * @private
   */
  private _check(
    redirectURL: string,
    routeFeature: string,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.dataStoreService.hasOffLineSync) {
      if (
        !this.dataStoreService.isOnline$.getValue() &&
        !['calendar', 'client-request'].includes(routeFeature)
      ) {
        return this._router.parseUrl('sign-in');
      } else if (
        !this.dataStoreService.isOnline$.getValue() &&
        ['calendar', 'client-request'].includes(routeFeature)
      ) {
        return true;
      }
    }
    // Check the authentication status
    return this._authService.check().pipe(
      switchMap((authenticated) => {
        // If the user is not authenticated...
        if (!authenticated) {
          // Redirect to the sign-in page
          return of(this._router.parseUrl('sign-in'));
        }
        const userAuth = this.dataStoreService.user$.getValue();
        if (userAuth) {
          // Set email and Id to Sentry
          return this._getMenuAndFeatures(routeFeature);
        }
        return this.userService.get().pipe(
          switchMap((user) => {
            // Set email and Id to Sentry
            this.dataStoreService.company$.next(user.companyId);
            this.dataStoreService.site$.next(user.defaultSite);
            return this._getMenuAndFeatures(routeFeature);
          }),
        );
      }),
    );
  }

  private _getMenuAndFeatures(routeFeature: string): Observable<boolean | UrlTree> {
    this.features = this.dataStoreService.features$.getValue();
    if (!this.features || !this.features.length) {
      return new Observable<boolean | UrlTree>((observer: Subscriber<boolean | UrlTree>) => {
        this.menuService.getMenu().subscribe({
          next: (data) => {
            this.dataStoreService.meun$.next(data.menu);
            this.features = data.features;
            this.dataStoreService.features$.next(this.features);
            const returnBoolean =
              data.features.findIndex((val) => val.code === routeFeature) !== -1;
            if (!returnBoolean) {
              // check status of default feature
              const menu: FuseNavigationItem[] = data.menu;
              const firstLink = this.getFirstLink(menu);
              if (firstLink) {
                observer.next(this._router.parseUrl(firstLink));
                observer.complete();
              }
            }
            observer.next(returnBoolean);
            observer.complete();
          },
          error: () => {
            observer.next(false);
            observer.complete();
          },
        });
      });
    } else {
      const returnBoolean =
        this.features.findIndex((val: FeatureAuth) => val.code === routeFeature) !== -1;
      if (!returnBoolean) {
        // check status of default feature
        const menu: FuseNavigationItem[] = this.dataStoreService.meun$.getValue();
        const firstLink = this.getFirstLink(menu);
        if (firstLink) {
          of(this._router.parseUrl(firstLink));
        } else {
          of(this._router.parseUrl('sign-in'));
        }
      }
      return of(returnBoolean);
    }
  }

  private getFirstLink(menu: FuseNavigationItem[]) {
    const children: FuseNavigationItem[] =
      menu[menu?.findIndex((m) => m.children && m.children.length > 0)]?.children;
    return children[children.findIndex((c) => !!c.link)]?.link;
  }
}
