import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FuseNavigationItem } from '../../../@fuse/components/navigation';
import { Company } from '../models/company';
import { Site } from '../models/site';
import { User } from '../models/user';
import { FeatureAuth } from '../models/feature-auth';
import { ParamProject } from '../models/paramProject';

@Injectable({
  providedIn: 'root',
})
export class DataStoreService {
  private hasOfflineSyncKey: string = 'hasOffLineSync';
  private refreshNeededKey = 'refreshNeeded';

  set hasOffLineSync(value: boolean) {
    localStorage.setItem(this.hasOfflineSyncKey, value.toString());
  }

  get hasOffLineSync() {
    return localStorage.getItem(this.hasOfflineSyncKey) === 'true';
  }

  set refreshNeeded(value: boolean) {
    localStorage.setItem(this.refreshNeededKey, value.toString());
  }

  get refreshNeeded(): boolean {
    return localStorage.getItem(this.refreshNeededKey) === 'true';
  }

  public meun$: BehaviorSubject<FuseNavigationItem[]> = new BehaviorSubject<FuseNavigationItem[]>(
    null,
  );
  company$ = new BehaviorSubject<Company>(null);
  paramProject$ = new BehaviorSubject<ParamProject>(null);
  site$ = new BehaviorSubject<Site>(null);
  sites$: BehaviorSubject<Site[]> = new BehaviorSubject<Site[]>(null);
  user$: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  defaultLink$ = new BehaviorSubject<string>(null);
  features$: BehaviorSubject<FeatureAuth[]> = new BehaviorSubject<FeatureAuth[]>(null);
  isOnline$ = new BehaviorSubject<boolean>(null);
}
