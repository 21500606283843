import { HttpClient } from '@angular/common/http';
import {
  TRANSLOCO_LOADER,
  Translation,
  TranslocoLoader,
  TRANSLOCO_CONFIG,
  translocoConfig,
  TranslocoModule,
  TranslocoService,
} from '@ngneat/transloco';
import { APP_INITIALIZER, Injectable, NgModule } from '@angular/core';
import { tap } from 'rxjs/operators';
import { lastValueFrom, Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { LocalProject } from '../shared/models/localProject';
import { Local } from '../shared/models/local';
import { PaginatorI18nService } from '../shared/services/paginator-i18n.service';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { Project } from '../shared/models/project';
import { DataStoreService } from '../shared/data-store/data-store.service';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  endpoint = `${environment.api}/internationalization`;
  code = environment.code;
  constructor(private http: HttpClient, private dataStoreService: DataStoreService) {}
  getTranslation(lang: string) {
    const connectedUser = localStorage.getItem('connectedUser');
    const url = `${this.endpoint}/${this.code}/${lang}?companyId=${
      connectedUser ? JSON.parse(connectedUser).companyId : null
    }`;
    return this.http.get<Translation>(url).pipe(tap((response: any) => response));
  }
  // get locals
  getAvailableLocals(): Observable<LocalProject[]> {
    if (!this.dataStoreService.isOnline$.getValue()) {
      return of([
        {
          _id: '63a1a49dc7af8500187add6a',
          localId: {
            _id: '624384f74c787f00121eaf2a',
            label: 'Français',
            key: 'fr-FR',
            flag: 'fr.svg',
            order: 3,
            orientation: 'ltr',
            __v: 0,
            createdAt: '2022-03-29T22:15:19.958Z',
            updatedAt: '2022-08-26T21:01:40.715Z',
          },
          projectId: '638de32cc7af8500187acd78',
          status: true,
          order: 2,
          usersCreation: '624384f74c787f00121eaf21',
          createdAt: '2022-12-20T12:03:41.625Z',
          updatedAt: '2023-12-13T11:54:13.329Z',
          __v: 0,
          usersLastUpdate: '624384f74c787f00121eaf21',
        } as LocalProject,
      ]);
    } else {
      return this.http
        .get<LocalProject[]>(`${this.endpoint}/${this.code}/locals`)
        .pipe(tap((response: any) => response));
    }
  }
  // get all locals
  getAllLocals(): Observable<Local[]> {
    if (!this.dataStoreService.isOnline$.getValue()) {
      return of([{ key: 'fr-FR' }]);
    } else {
      return this.http.get<Local[]>(`${this.endpoint}`).pipe(tap((response: any) => response));
    }
  }
  // get locals
  getDefaultLocal(): Observable<Local> {
    if (!this.dataStoreService.isOnline$.getValue()) {
      return of({ key: 'fr-FR' });
    } else {
      return this.http
        .get<Local>(`${this.endpoint}/${this.code}/default`)
        .pipe(tap((response: any) => response));
    }
  }
  // get Project
  getProject(): Observable<Project> {
    return this.http
      .get<Project>(`${this.endpoint}/project/${this.code}`)
      .pipe(tap((response: any) => response));
  }
}
@NgModule({
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        defaultLang: 'fr-FR',
        availableLangs: ['en-US', 'fr-FR'],
        reRenderOnLangChange: true,
        prodMode: environment.production,
      }),
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
    {
      // Preload the default language before the app starts to prevent empty/jumping content
      provide: APP_INITIALIZER,
      deps: [TranslocoService],
      useFactory:
        (translocoService: TranslocoService): any =>
        (): Promise<Translation> => {
          const defaultLang = translocoService.getDefaultLang();
          translocoService.setActiveLang(defaultLang);
          return lastValueFrom(translocoService.load(defaultLang));
        },
      multi: true,
    },
    {
      provide: MatPaginatorIntl,
      deps: [TranslocoService],
      useFactory: (translate) => {
        const service = new PaginatorI18nService();
        service.injectTranslateService(translate);
        return service;
      },
    },
  ],
})
export class TranslocoRootModule {}
