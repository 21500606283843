<!-- Navigation -->
<app-fuse-vertical-navigation
  class="dark bg-gray-900 print:hidden"
  [mode]="isScreenSmall ? 'over' : 'side'"
  [name]="'mainNavigation'"
  [navigation]="navigation"
  [opened]="!isScreenSmall"
>
  <!-- Navigation header hook -->
  <ng-container fuseVerticalNavigationContentHeader>
    <!-- Logo -->

    <div class="flex w-1/2 justify-start m-2">
      <img src="assets/images/logo/help.png" />
    </div>
    <div class="flex flex-col items-center w-full p-2 pt-8">
      <!-- Components -->
      <div class="flex flex-col items-center my-2" *ngIf="connectedCompany">
        <div class="relative flex flex-0 items-center justify-center w-50">
          <ng-container>
            <ng-container *ngIf="connectedCompany?.logo">
              <img
                class="w-30 h-30 rounded-full object-cover"
                [src]="urlLogo + connectedCompany?.logo"
                alt="Contact avatar"
              />
            </ng-container>
            <ng-container *ngIf="!connectedCompany?.logo">
              <div
                class="flex items-center justify-center w-30 h-30 rounded-full text-8xl uppercase bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200"
              >
                {{ connectedCompany?.name.charAt(0) }}
              </div>
            </ng-container>
          </ng-container>
        </div>
        <div class="flex flex-col items-center my-4">
          <span class="text-xl"> {{ connectedCompany?.name }} </span>
          <span class="text-secondary">
            {{ connectedSite?.label }}
          </span>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- Navigation footer hook -->
  <ng-container fuseVerticalNavigationContentFooter>
    <div
      class="flex flex-0 items-center justify-center h-16 pr-6 pl-2 mt-8 mb-2 opacity-50 mx-auto"
    ></div>
  </ng-container>
</app-fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
  <!-- Header -->
  <div
    class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden"
  >
    <!-- Navigation toggle button -->
    <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
      <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
    </button>

    <ng-template #separatorTemplate>
      <mat-icon svgIcon="heroicons_outline:chevron-right"></mat-icon>
    </ng-template>
    <!-- Components -->
    <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
      <app-app-languages></app-app-languages>
      <app-notifications *ngIf="false"></app-notifications>test
      <app-user
        (refreshSite)="refreshSite()"
        (refreshCompany)="refreshCompany()"
        [showAvatar]="true"
        *ngIf="!isLoading"
      ></app-user>
    </div>
  </div>

  <!-- Content -->
  <div class="flex flex-col flex-auto" *ngIf="!isLoading && !isLoadingSites">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
    <router-outlet></router-outlet>
  </div>

  <!-- Footer -->
  <!--<div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">Fuse &copy; {{currentYear}}</span>
    </div>-->
</div>

<!-- Quick chat -->
