import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { tap } from 'rxjs/operators';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { environment } from '../../../environments/environment';
import { ResToken, User } from '../models/user';
import { Pagination } from '../models/pagination';
import { UserSite } from '../models/user-site';
import { FeatureAuth } from '../models/feature-auth';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  endpoint = `${environment.api}/users`;
  endpointAuth = `${environment.services.auth.url}/users`;
  keyAccessToken = 'access_token';
  keyOriginRoute = 'origin_route';
  _user: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  _defaultLink = new BehaviorSubject<string>(null);
  private _features: BehaviorSubject<FeatureAuth[]> = new BehaviorSubject<FeatureAuth[]>(null);

  constructor(private http: HttpClient, private router: Router) {}
  set user(value: User) {
    // Store the value
    this._user.next(value);
  }

  get user$(): Observable<User> {
    return this._user.asObservable();
  }

  /**
   * Setter & getter for features
   *
   * @param value
   */
  set features(value: FeatureAuth[]) {
    // Store the value
    this._features.next(value);
  }
  get features$(): BehaviorSubject<FeatureAuth[]> {
    return this._features;
  }
  get defaultLink$(): BehaviorSubject<string> {
    return this._defaultLink;
  }
  // Sign-up
  signUp(user: User, userSites, params): Observable<User> {
    return this.http.post<User>(`${this.endpointAuth}`, {
      user,
      userSites,
      params,
      projectCode: environment.code,
    });
  }

  // valid code
  checkCode(user: User): Observable<ResToken> {
    return this.http.post<ResToken>(`${this.endpointAuth}/check-code`, user);
  }
  decodeToken(token: string): JwtPayload {
    try {
      return jwtDecode(token);
    } catch (e) {
      console.error(e);
      this.doLogout();
      return null;
    }
  }
  doLogout(): Observable<boolean> {
    // Remove the access token from the local storage
    localStorage.removeItem('accessToken');

    // Return the observable
    return of(true);
  }
  // change password
  changePassword(password: string, newPassword: string): Observable<null> {
    return this.http.post<null>(`${this.endpointAuth}/change-password`, { password, newPassword });
  }

  // update personal info
  updatePersonalInfo(user: User): Observable<User> {
    return this.http.put<User>(`${this.endpointAuth}/personal-info`, user);
  }

  // get profile
  getUserProfile(): Observable<User> {
    return this.http.get<User>(`${this.endpointAuth}/me`);
  }
  getSingleUserProfile(id: string): Observable<User> {
    return this.http.get<User>(`${this.endpointAuth}/${id}`);
  }
  // update user
  updateUser(user: User, userSites): Observable<User> {
    return this.http.patch<User>(`${this.endpointAuth}/${user._id}`, { user, userSites });
  }
  enableAccount(id: string): Observable<User> {
    return this.http.get<User>(`${this.endpointAuth}/${id}/enable-disable`);
  }

  // get Users
  getUsers(limit, page, search, sort): Observable<Pagination<User>> {
    let searchParams = new HttpParams();
    searchParams = searchParams.append('limit', limit);

    searchParams = searchParams.append('page', page);
    searchParams = searchParams.append('sort', sort);
    if (search) {
      searchParams = searchParams.append('search', search);
    }
    return this.http.get<Pagination<User>>(`${this.endpointAuth}`, {
      params: searchParams,
    });
  }

  //* *** AUT ****
  /**
   * Get the current logged-in user data
   */
  get(): Observable<User> {
    return this.http.get<User>(`${this.endpointAuth}/me`).pipe(
      tap((user) => {
        this._user.next(user);
      }),
    );
  }

  // change password

  checkPassword(password: string): Observable<null> {
    return this.http.post<null>(`${this.endpointAuth}/check-password`, {
      password,
    });
  }

  deleteUser(id): Observable<null> {
    return this.http.delete<null>(`${this.endpointAuth}/${id}`);
  }
  // update avatar profile
  updateAvatar(data: FormData, id): Observable<User> {
    return this.http.post<User>(`${this.endpointAuth}/${id}/avatar`, data);
  }
  getUserSites(id): Observable<UserSite[]> {
    return this.http.get<UserSite[]>(`${this.endpointAuth}/${id}/user-sites`);
  }
  // resend code
  resendCode(id: string, params: any): Observable<null> {
    return this.http.patch<null>(`${this.endpointAuth}/${id}/resend-code`, {
      params,
      projectCode: environment.code,
    });
  }

  checkPermission(permissions: FeatureAuth[]): boolean {
    const featuresAuth: FeatureAuth[] = this.features$.getValue();
    let permission: FeatureAuth;
    // eslint-disable-next-line no-restricted-syntax
    for (permission of permissions) {
      // eslint-disable-next-line @typescript-eslint/no-loop-func
      const fa: FeatureAuth = featuresAuth.find((fau: FeatureAuth) => fau.code === permission.code);
      if (!fa) {
        return false;
      }
      let permissionAction;
      // eslint-disable-next-line no-restricted-syntax
      for (permissionAction of permission.actions) {
        if (!fa.actions.includes(permissionAction)) {
          return false;
        }
      }
    }
    return true;
  }
}
