import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { environment } from '../../../environments/environment';
import { DataStoreService } from '../../shared/data-store/data-store.service';
import jwtDecode, { JwtPayload } from 'jwt-decode';

@Injectable()
export class AuthService {
  endpointAuth = `${environment.services.auth.url}/users`;
  private _authenticated = false;
  accessTokenKey = 'accessToken';
  connectedUserKey = 'connectedUser';
  lastConnectedUserIdKey = 'lastConnectedUserId';
  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient, private dataStoreService: DataStoreService) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    localStorage.setItem(this.accessTokenKey, token);
  }

  get accessToken(): string {
    return localStorage.getItem(this.accessTokenKey) ?? '';
  }

  get authenticated(): boolean {
    return this._authenticated;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Forgot password
   *
   * @param email
   */
  forgotPassword(email: string): Observable<any> {
    return this._httpClient.post(`${this.endpointAuth}/forgot-password`, {
      email,
      projectCode: environment.code,
      resetUrl: `${environment.api}/confirmation-required?email=${encodeURIComponent(
        email.toLowerCase(),
      )}&type=forgot`,
    });
  }

  /**
   * Reset password
   *
   * @param password
   */
  resetPassword(password: string): Observable<any> {
    return this._httpClient.post(`${environment.services.auth.url}/reset-password`, password);
  }

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(credentials: { email: string; password: string }): Observable<any> {
    // Throw error, if the user is already logged in
    if (this._authenticated) {
      throw Error('User is already logged in.');
    }

    return this._httpClient
      .post(`${this.endpointAuth}/login`, { ...credentials, projectCode: environment.code })
      .pipe(
        switchMap((response: any) => {
          // Store the access token in the local storage
          this.accessToken = response;
          // Set the authenticated flag to true
          this._authenticated = true;
          // Return a new observable with the response
          return of(response);
        }),
      );
  }

  // *********************** TOKEN ******************************//
  decodeToken(token: string): JwtPayload {
    try {
      return jwtDecode(token);
    } catch (e) {
      console.error(e);
      this.doLogout();
      return null;
    }
  }

  deleteToken(): void {
    localStorage.removeItem(this.accessTokenKey);
  }
  doLogout(): Observable<boolean> {
    // Remove the access token from the local storage
    localStorage.removeItem(this.accessTokenKey);

    // Return the observable
    return of(true);
  }
  isTokenExpired(expiryTime: number): boolean {
    if (expiryTime) {
      return expiryTime - new Date().getTime() / 1000 <= 5;
    }
    return false;
  }

  refreshToken(body): Observable<any> {
    body.projectCode = environment.code;
    return this._httpClient.post(`${this.endpointAuth}/refresh-token`, body).pipe(
      switchMap((response: any) => {
        // Store the access token in the local storage
        this.accessToken = response;
        const connectedUser = JSON.stringify(this.decodeToken(response));
        localStorage.setItem(
          'connectedUser',
          JSON.stringify({
            ...JSON.parse(connectedUser),
            _id: JSON.parse(connectedUser).id,
          }),
        );
        return of(response);
      }),
    );
  }

  /**
   * Sign in using the access token
   */
  signInUsingToken(): Observable<any> {
    // Renew token
    return this._httpClient
      .post(`${this.endpointAuth}/refresh-access-token`, {
        accessToken: this.accessToken,
      })
      .pipe(
        catchError(() =>
          // Return false
          of(false),
        ),
        switchMap((response: any) => {
          // Store the access token in the local storage
          this.accessToken = response.token;

          // Set the authenticated flag to true
          this._authenticated = true;

          // Store the user on the user service
          this.dataStoreService.user$.next(response.user);

          // Return true
          return of(true);
        }),
      );
  }

  /**
   * Sign out
   */
  signOut(): Observable<any> {
    // Remove the access token from the local storage
    localStorage.removeItem('accessToken');
    localStorage.removeItem('connectedSite');

    // Set the authenticated flag to false
    this._authenticated = false;

    // Return the observable
    return of(true);
  }

  signOutOffline(): void {
    // Set the authenticated flag to false
    this._authenticated = false;
  }

  /**
   * Sign up
   *
   * @param user
   */
  signUp(user: {
    fullName: string;
    email: string;
    password: string;
    company: string;
  }): Observable<any> {
    return this._httpClient.post(`${this.endpointAuth}/sign-up`, {
      user,
      codeProject: environment.code,
      confirmationSignUpUrl: `${environment.api}/confirmation-sign-up?email=${encodeURIComponent(
        user.email.toLowerCase(),
      )}`,
    });
  }

  /**
   * Unlock session
   *
   * @param credentials
   */
  unlockSession(credentials: { email: string; password: string }): Observable<any> {
    return this._httpClient.post(`${environment.services.auth.url}/unlock-session`, credentials);
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {
    // Check if the user is logged in
    if (this._authenticated) {
      return of(true);
    }

    // Check the access token availability
    if (!this.accessToken || this.accessToken === 'undefined') {
      localStorage.removeItem('accessToken');
      return of(false);
    }

    // Check the access token expire date
    if (AuthUtils.isTokenExpired(this.accessToken)) {
      return of(false);
    }
    this._authenticated = true;
    return of(true);
  }

  signInWithNewPass(email: string, password: string, code: string) {
    return this._httpClient
      .patch<null>(`${this.endpointAuth}/login-with-code`, {
        email,
        password,
        code,
        projectCode: environment.code,
      })
      .pipe(
        switchMap((response: any) => {
          // Store the access token in the local storage
          this.accessToken = response;
          // Set the authenticated flag to true
          this._authenticated = true;
          // Return a new observable with the response
          return of(response);
        }),
      );
  }
  activateSignUpAccount(email: string, code: string) {
    return this._httpClient
      .patch<null>(`${this.endpointAuth}/activate-with-code`, {
        email,
        code,
        projectCode: environment.code,
      })
      .pipe(
        switchMap((response: any) => {
          // Store the access token in the local storage
          this.accessToken = response;
          // Set the authenticated flag to true
          this._authenticated = true;
          // Return a new observable with the response
          return of(response);
        }),
      );
  }

  resetPasswordByEmail(email: string, newPassword: string, code: string): Observable<null> {
    return this._httpClient
      .post<null>(`${this.endpointAuth}/change-password`, {
        email,
        newPassword,
        code,
        projectCode: environment.code,
      })
      .pipe(
        switchMap((response: any) => {
          // Store the access token in the local storage
          this.accessToken = response;
          // Set the authenticated flag to true
          this._authenticated = true;
          // Return a new observable with the response
          return of(response);
        }),
      );
  }
}
